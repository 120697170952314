import { createRouter, createWebHistory } from "vue-router";
import type { RouteRecordRaw } from "vue-router";
const routes: RouteRecordRaw[] = [
  {
    path: "/",
    name: "home",
    component: () => import("../views/HomeView.vue"),
  },

  {
    path: "/about",
    name: "about",
    component: () => import("../views/AboutView/AboutView.vue"),
  },

  {
    path: "/case-studies",
    name: "caseStudies",
    redirect: null,
    children: [
      {
        path: "mont-saint-michel",
        name: "msm",
        component: () => import("../views/MontStMichel/MontStMichel.vue"),
      },
      {
        path: "leap-for-mankind",
        name: "leap",
        component: () => import("../views/LeapForMankind/LeapForMankind.vue"),
      },
      {
        path: "behind-the-scenes",
        name: "bts",
        component: () =>
          import("../views/BehindTheScenesView/BehindTheScenesView.vue"),
      },
    ],
  },

  {
    path: "/credits",
    name: "credits",
    component: () => import("../views/CreditView/CreditView.vue"),
  },
  {
    path: "/error404",
    name: "error404",
    component: () => import("../views/Error/Error.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    name: "not-found",
    redirect: "/error404",
  },
];

// Add design system route only in development
if (import.meta.env.DEV) {
  routes.push({
    path: "/design-system",
    name: "designSystem",
    component: () => import("../views/DesignSystem/DesignSystem.vue"),
  });
}

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
});

export default router;
