const IS_DEBUG = false;
const IS_TRANSITION_DEBUG = false;
const IS_LOADING_FRAME = false;
const IS_CAMERA_INTRO_DEBUG = false;
const IS_CAMERA_FOLLOW_PLAYER_DEBUG = false;

export {
  IS_DEBUG,
  IS_TRANSITION_DEBUG,
  IS_LOADING_FRAME,
  IS_CAMERA_INTRO_DEBUG,
  IS_CAMERA_FOLLOW_PLAYER_DEBUG,
};
