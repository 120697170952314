<template>
  <button-base
    class="button-key"
    :class="cssClasses"
    :markup="markup"
    :aria-label="ariaLabel ?? label"
  >
    <icon-base
      :name="iconBackgroundName"
      :size="iconBackgroundSize"
    ></icon-base>
    <div class="button-key__inner">
      <span v-if="!iconName" class="button-key__inner__label">{{ label }}</span>
      <icon-base
        v-else
        :name="iconName"
        :size="iconSize"
        :rotation="iconRotation"
      ></icon-base>
    </div>
  </button-base>
</template>

<script setup lang="ts">
import { computed } from "vue";

import IconBase from "../IconBase/IconBase.vue";
import ButtonBase from "../ButtonBase";

import type { ButtonKeyProps } from "./types";

const props = withDefaults(defineProps<ButtonKeyProps>(), {
  label: null,
  iconName: null,
  iconSize: 1,
  iconRotation: 0,
  isActive: false,
  markup: "button",
  ariaLabel: null,
});

const cssClasses = computed(() => [
  props.theme && `-${props.theme}`,
  props.isClickable && "-clickable",
  props.isActive && "-active",
]);

const iconBackgroundName = computed(() =>
  props.theme === "square" ? "key-square" : "key-rectangle",
);

const iconBackgroundSize = computed(() => (props.theme === "square" ? 5 : 10));
</script>

<style lang="scss" scoped>
.button-key {
  --button-key-color: var(--color-judge-gray);
  --button-key-background-color: transparent;

  --button-key-inner-right: 0.1rem;
  --button-key-inner-bottom: 0.1rem;

  &.-square {
    --button-key-inner-right: 0.2rem;
    --button-key-inner-bottom: 0.2rem;
  }

  &.-active {
    --button-key-color: var(--color-cod-gray);
    --button-key-background-color: var(--color-judge-gray);
  }

  :deep(svg) {
    height: 5rem;
  }

  &__inner {
    @include stack(
      "vertical",
      "center",
      "center",
      $gap: var(--spacer-md),
      $wrap: wrap,
      $var-prefix: "stack"
    );
    position: absolute;
    top: 0.1rem;
    left: 0.1rem;
    right: var(--button-key-inner-right);
    bottom: var(--button-key-inner-bottom);

    color: var(--button-key-color);

    background-color: var(--button-key-background-color);
    transition:
      background-color 0.5s ease,
      color 0.5s ease;

    :deep(svg) {
      fill: var(--button-key-color);
      transition: fill 0.5s ease;
    }

    &__label {
      @include t(p3-serif-bold);
    }
  }
}
</style>
