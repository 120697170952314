import { createApp } from "vue";
import { createPinia } from "pinia";

import { i18n } from "@/services/i18n";

import "virtual:svg-icons-register";
import { viewDirective } from "@/directives/view";

import { VueUmamiPlugin } from "@jaseeey/vue-umami-plugin";

import App from "./App.vue";
import router from "./router";

import * as Sentry from "@sentry/vue";

const app = createApp(App);

Sentry.init({
  app,
  dsn: import.meta.env.VITE_SENTRY_DSN,
});

app.use(
  VueUmamiPlugin({
    websiteID: import.meta.env.VITE_UMAMI_WEBSITE_ID,
    scriptSrc: "https://cloud.umami.is/script.js", // Optional
    router,
    allowLocalhost: false,
  }),
);

app.use(createPinia());
app.use(i18n);
app.use(router);
app.directive("view", viewDirective);

app.mount("#app");
