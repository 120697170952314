<template>
  <queso-clickable
    class="c-button-base"
    :class="btnClasses"
    :markup="buttonTag"
    :href="props.href"
  >
    <slot name="iconBefore"></slot>

    <span class="c-button-base__label">
      <slot></slot>
    </span>

    <slot name="iconAfter"></slot>
  </queso-clickable>
</template>

<script setup lang="ts">
import { computed } from "vue";

import type { ButtonBaseProps } from "./types";
import { QuesoClickable } from "@allomambo/queso";

const props = withDefaults(defineProps<ButtonBaseProps>(), {
  size: "medium",
  tag: "button",
  isLoading: false,
  href: undefined,
});

const buttonTag = computed(() => (props.href ? "a" : props.tag));

const btnClasses = computed<string[]>(() => [
  `-size-${props.size}`,
  props.isLoading && "is-loading",
]);
</script>

<style lang="scss" scoped>
.c-button-base {
  --btn-base-gap: var(--spacer-xs);
  display: flex;
  align-items: center;

  gap: var(--btn-base-gap);
  position: relative;

  outline: none !important;

  &__label {
    font-size: var(--btn-font-size);
    font-weight: var(--btn-font-weight);

    &:empty {
      display: none;
    }
  }

  /*==============================
    =           STATES             =
    ==============================*/

  //--- Disabled ---//
  &.is-disabled {
    opacity: 0.4;
  }

  //--- Loading ---//
  &__loader {
    @include fullscreen;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 3;
    color: var(--btn-loading-icon-color, var(--color-black));
    opacity: var(--btn-loader-opacity);
    transition: opacity 0.15s ease;

    .queso-icon {
      --icon-width: var(--btn-loading-icon-width, 2.2rem);
      animation: spin 0.6s linear infinite;

      @keyframes spin {
        from {
          transform: rotate(0deg);
        }

        to {
          transform: rotate(360deg);
        }
      }
    }
  }

  &.is-loading {
    @include unselectable;
    --btn-loader-opacity: 1;
  }
}
</style>
