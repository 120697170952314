<template>
  <div class="cursor-trail">
    <canvas
      ref="canvas"
      class="cursor-trail__canvas"
      id="cursor-card"
    ></canvas>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted, onUnmounted, computed, watch } from "vue";
import { useRoute } from "vue-router";

import { storeToRefs } from "pinia";
import { useIdleStore } from "@/stores/idle";
import { useNavigationStore } from "@/stores/navigation";
import { useLoadingStore } from "@/stores/loading";
import { useHighlightStore } from "@/stores/highlight";
import { useResize } from "@/composables/useResize";

const idleStore = useIdleStore();
const loadingStore = useLoadingStore();
const navigationStore = useNavigationStore();
const highlightStore = useHighlightStore();

const { isIdleVisible } = storeToRefs(idleStore);
const { isOpen } = storeToRefs(navigationStore);
const { isLoadingVisible } = storeToRefs(loadingStore);
const { isVisible: isHighlightVisible } = storeToRefs(highlightStore);

const { width } = useResize();

const route = useRoute();
const isRouteNameHome = computed(() => route.name === "home");

const FADE_SPEED = 5;
const PAW_SIZE = 10;
const MAX_PAWS = 25;
const DISTANCE_THRESHOLD = 50;

const isVisible = computed(
  () =>
    !(
      isIdleVisible.value ||
      (isRouteNameHome.value &&
        !isHighlightVisible.value &&
        !(isLoadingVisible.value || isOpen.value))
    ),
);

const canvas = ref<HTMLCanvasElement | null>(null);
let ctx: CanvasRenderingContext2D | null = null;
let animationFrameId: number;
const worker = new Worker(
  new URL("@/workers/cursorTrail.worker.ts", import.meta.url),
  { type: "module" },
);
const paws = ref<Paw[]>([]);
let prevPawLeft = false;
const mouse = { prev: { x: 0, y: 0 }, dist: 0 };
let pmouseX = 0;
let pmouseY = 0;

const pawImage = new Image();
pawImage.src = "/static/textures/other/paw/paw.svg"; // Replace with your image path

const draw = () => {
  if (!ctx || !canvas.value) return;
  ctx.clearRect(0, 0, canvas.value.width, canvas.value.height);

  if (isVisible.value) {
    // Convert paws to a simple serializable format
    const serializablePaws = paws.value.map((paw) => ({
      x: paw.x,
      y: paw.y,
      alpha: paw.alpha,
      size: paw.size,
      left: paw.left,
      angle: paw.angle,
      offset: paw.offset,
    }));

    worker.postMessage({
      type: "UPDATE_PAWS",
      data: { paws: serializablePaws },
    });

    paws.value.forEach((paw) => {
      if (!ctx || paw.alpha <= 0) return;
      ctx.save();
      ctx.globalAlpha = paw.alpha / 255;
      ctx.translate(paw.x - paw.size / 2, paw.y - paw.size / 2);
      ctx.rotate(paw.angle);
      ctx.translate(paw.offset, 0);
      ctx.drawImage(pawImage, -paw.size, -paw.size, paw.size * 2, paw.size * 2);
      ctx.restore();
    });
  }

  animationFrameId = requestAnimationFrame(draw);
};

let lastMove = 0;
const THROTTLE_MS = 16;

const handleMouseMove = (e: MouseEvent) => {
  if (!isVisible.value) return;

  const now = Date.now();
  if (now - lastMove < THROTTLE_MS) return;
  lastMove = now;

  if (!canvas.value) return;
  const rect = canvas.value.getBoundingClientRect();
  const mouseX = e.clientX - rect.left;
  const mouseY = e.clientY - rect.top;

  if (
    mouseX >= 0 &&
    mouseY >= 0 &&
    mouseX <= canvas.value.width &&
    mouseY <= canvas.value.height
  ) {
    const dx = Math.abs(mouseX - pmouseX);
    const dy = Math.abs(mouseY - pmouseY);

    if (mouse.dist > DISTANCE_THRESHOLD) {
      if (paws.value.length >= MAX_PAWS) {
        paws.value.shift();
      }

      worker.postMessage({
        type: "CALCULATE_PAW",
        data: {
          mouseX,
          mouseY,
          prevX: mouse.prev.x || pmouseX,
          prevY: mouse.prev.y || pmouseY,
          prevLeft: prevPawLeft,
        },
      });

      mouse.dist = 0;
      mouse.prev = { x: mouseX, y: mouseY };
    } else {
      mouse.dist += dx + dy;
    }
    pmouseX = mouseX;
    pmouseY = mouseY;
  } else {
    mouse.prev = { x: 0, y: 0 };
  }
};

// Add worker message handler
worker.onmessage = (e: MessageEvent) => {
  const { type, data } = e.data;

  switch (type) {
    case "PAWS_UPDATED":
      paws.value = data.paws;
      break;
    case "NEW_PAW":
      paws.value.push(data.paw);
      prevPawLeft = data.left;
      break;
  }
};

watch(width, () => {
  handleResize();
});

const handleResize = () => {
  if (!canvas.value) return;
  canvas.value.width = window.innerWidth;
  canvas.value.height = window.innerHeight;
};

onMounted(() => {
  if (!canvas.value) return;
  ctx = canvas.value.getContext("2d", { alpha: true });

  handleResize();
  window.addEventListener("resize", handleResize);
  window.addEventListener("mousemove", handleMouseMove);
  animationFrameId = requestAnimationFrame(draw);

  onUnmounted(() => {
    window.removeEventListener("resize", handleResize);
    window.removeEventListener("mousemove", handleMouseMove);
    cancelAnimationFrame(animationFrameId);
    worker.terminate();
  });
});
</script>

<style lang="scss" scoped>
.cursor-trail {
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0rem;
  right: 0rem;
  bottom: 0rem;
  left: 0rem;
  z-index: 99999;
  position: fixed;
  pointer-events: none;

  &__canvas {
    width: 100%;
    height: 100%;

    pointer-events: none;
  }
}
</style>
