import { defineStore } from "pinia";

interface navigationState {
  isOpen: boolean;
  isFirstOpening: boolean;
}
export const useNavigationStore = defineStore("navigation", {
  state: () =>
    ({
      isOpen: false,
      isFirstOpening: true,
    }) as navigationState,
  getters: {
    getIsOpen: (state) => state.isOpen,
  },
  actions: {
    toggleNavigation(bool: boolean) {
      window.experience?.world?.bleed?.playAnimationBleed(bool);
      this.isOpen = bool;
      !bool ? this.setIsFirstOpening(false) : null;

      if (bool) {
        window.experience.playerDirection?.stopAllMovement();
        window.experience.playerSound?.stopRaccoonWalkSound("walk");
        window.experience.playerSound?.stopRaccoonWalkSound("run");
      }
    },
    setIsFirstOpening(bool: boolean) {
      this.isFirstOpening = bool;
    },
    closeNavigation() {
      this.isOpen = false;
      this.isFirstOpening = false;
    },
  },
});
