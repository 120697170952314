<template>
  <div class="tutorial-panel-item">
    <div class="tutorial-panel-item__content">
      <slot></slot>
    </div>
    <span class="tutorial-panel-item__label">{{ props.label }}</span>
  </div>
</template>

<script setup lang="ts">
import type { TutorialPanelItemProps } from "./types";

const props = withDefaults(defineProps<TutorialPanelItemProps>(), {});
</script>

<style lang="scss" scoped>
.tutorial-panel-item {
  @include stack(
    "vertical",
    "center",
    "bottom",
    $gap: var(--spacer-xs),
    $wrap: wrap,
    $var-prefix: "stack-tutorial-panel-item"
  );

  &__label {
    @include t("p4");
  }
}
</style>
