<template>
  <div class="loader-body" :class="cssClasses">
    <div class="loader-body__inner">
      <title-decorative
        :is-decor-visible="true"
        :is-playing="isVisible"
        :body="'loader.title'"
      ></title-decorative>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import TitleDecorative from "@/components/TitleDecorative";

import type { TheLoaderBodyProps } from "./types";

const cssClasses = computed(() => [props.isVisible && "-visible"]);

const props = withDefaults(defineProps<TheLoaderBodyProps>(), {});
</script>

<style lang="scss" scoped>
.loader-body {
  --loader-body-progress-opacity: 0;
  @include stack(
    "vertical",
    "center",
    "center",
    $gap: var(--spacer-md),
    $wrap: wrap,
    $var-prefix: "stack"
  );

  &.-visible {
    --loader-body-progress-opacity: 1;
  }

  &__inner {
    @include stack(
      "vertical",
      "center",
      "center",
      $gap: var(--spacer-sm),
      $wrap: wrap,
      $var-prefix: "stack"
    );

    &__progress {
      opacity: var(--loader-body-progress-opacity);
      transition: opacity 1s ease;
    }
  }
}
</style>
