import { defineStore } from "pinia";
import type { audioState } from "./audio.types";
export const useAudioStore = defineStore("audio", {
  state: () =>
    ({
      current: "hayden-folker-devoted.mp3",
      isMute: true,
      currentFx: null,
    }) as audioState,
  getters: {
    getCurrentFx: (state) => state.currentFx,
    getIsMute: (state) => state.isMute,
  },
  actions: {
    toggleIsMute(isMute: boolean, isSoundEnabled: boolean = true) {
      this.isMute = isMute;

      // avoid console log error if sounds are not enabled in a threejs scene
      // isSoundEnabled
      //   ? window.experience?.audioGenerator?.enableAudio(!isMute)
      //   : null;
    },
    setCurrentFx(fx: string) {
      this.currentFx = fx;
    },
    clearCurrentFx() {
      this.currentFx = null;
    },
  },
});
