import { defineStore } from "pinia";
import type { idleState } from "./idle.types";

export const useIdleStore = defineStore("idle", {
  state: () =>
    ({
      isFirstVisit: true, // true as default
      isIdleVisible: true, // avoid loading page to show when user land in the home page, but still display the page before the route change
      // isFirstVisit: false, // true as default
      // isIdleVisible: false, // avoid loading page to show when user land in the home page, but still display the page before the route change
    }) as idleState,

  getters: {
    getIsFirstVisit: (state) => state.isFirstVisit,
    getIsIdleVisible: (state) => state.isIdleVisible,
  },
  actions: {
    disableFirstVisit() {
      this.isFirstVisit = false;
    },

    hideIdle() {
      this.isIdleVisible = false;
    },
  },
});
