import { defineStore } from "pinia";
import type { gameState, gameIds } from "./game.types";

import Cookies from "js-cookie";

const COOKIE_EXPIRY = 7;

export const useGameStore = defineStore("game", {
  state: () =>
    ({
      currentGame: null,
      total: 4,
      leap: false,
      msm: false,
      about: false,
      archives: false,
      // leap: true,
      // msm: true,
      // about: true,
      // archives: true,
    }) as gameState,

  getters: {
    isGameVisible: (state) => state.currentGame !== null,
    isLocked: (state) => {
      const completedFeatures =
        Number(state.leap) +
        Number(state.msm) +
        Number(state.about) +
        Number(state.archives);
      return completedFeatures < state.total;
    },
    totalKeys: (state) => {
      return state.total;
    },
    keys: (state) => {
      return (
        Number(state.leap) +
        Number(state.msm) +
        Number(state.about) +
        Number(state.archives)
      );
    },
  },
  actions: {
    setCurrentGame(game: gameState["currentGame"]) {
      this.currentGame = game;
    },

    updateCompletedGame(feature: gameIds) {
      this[feature] = true;
      Cookies.set(feature, "true", { expires: COOKIE_EXPIRY });
    },
    updateCompletedGameFromCookie() {
      this.leap = Cookies.get("leap") === "true";
      this.msm = Cookies.get("msm") === "true";
      this.about = Cookies.get("about") === "true";
      this.archives = Cookies.get("archives") === "true";
    },
  },
});
