<template>
  <div class="audio-player" />
</template>

<script setup lang="ts">
import { watch } from "vue";
import { useAudioStore } from "@/stores/audio";
import { Howl } from "howler";

let timeoutStopMusic = null as any;

const sound = new Howl({
  src: [
    "/static/audio/music/wombat-noises-audio-dreams-of-an-alpine-spring.mp3",
  ],
  // src: ["/static/audio/music/aeon.mp3"],
  // src: [],
  volume: 0,
  loop: true,
  onplay: () => {
    sound.fade(sound.volume(), 1, 1000);
  },
});

const toggleAudio = (isMute: boolean) => {
  isMute ? stopMusic() : playAudio();
};

const playAudio = () => {
  if (timeoutStopMusic) {
    clearTimeout(timeoutStopMusic);
    timeoutStopMusic = null;
  }
  sound.play();
};

const stopMusic = () => {
  if (timeoutStopMusic) return;
  const playingId = sound.playing();
  sound.fade(sound.volume(), 0, 1000, playingId ? playingId.id : undefined);
  timeoutStopMusic = setTimeout(() => {
    sound.pause();
    clearTimeout(timeoutStopMusic);
    timeoutStopMusic = null;
  }, 1000);
};

const audioStore = useAudioStore();

watch(
  () => audioStore.isMute,
  (isMute) => {
    toggleAudio(isMute);
  }
);
</script>
<style lang="scss" scoped>
.audio-player {
}
</style>
