// composables/useResize.ts
import { ref, onMounted, onBeforeUnmount } from "vue";
import { debounce } from "lodash";

export function useResize() {
  const width = ref(window.innerWidth);
  const height = ref(window.innerHeight);

  const handleResize = debounce(() => {
    width.value = window.innerWidth;
    height.value = window.innerHeight;
  }, 200); // Adjust the debounce delay as needed

  onMounted(() => {
    window.addEventListener("resize", handleResize);
  });

  onBeforeUnmount(() => {
    window.removeEventListener("resize", handleResize);
  });

  return { width, height };
}
