import { defineStore } from "pinia";

interface highlightState {
  isVisible: boolean;
  selectedProject: string | null;
}

export const useHighlightStore = defineStore("highlight", {
  state: () =>
    ({
      isVisible: false,
      selectedProject: null,
    }) as highlightState,

  actions: {
    openHighlight(project: string) {
      this.isVisible = true;
      this.selectedProject = project;
      this.toggleDisableKey(true);
    },

    closeHighlight() {
      this.isVisible = false;
      this.toggleDisableKey(false);
    },

    toggleDisableKey(bool: boolean) {
      window.experience?.playerDirection?.toggleIsKeyDisabled(bool);
    },

    setSelectedProject(project: string) {
      this.selectedProject = project;
    },
  },
});
