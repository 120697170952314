<template>
  <div>
    <audio ref="audioPlayer" @ended="onAudioEnded" />
  </div>
</template>

<script setup lang="ts">
import { ref, watch, computed } from "vue";
import { useAudioStore } from "@/stores/audio";
import type { FxPlayerProps } from "./types";

const audioPlayer = ref<HTMLAudioElement | null>(null);

const audioSources = {
  explosion: "/static/audio/sounds/frontEnd/explosion.mp3",
  tear: "/static/audio/sounds/frontEnd/tear.mp3",
  paper: "/static/audio/sounds/frontEnd/paper.mp3",
  boom: "/static/audio/sounds/frontEnd/boom.mp3",
  wind: "/static/audio/sounds/frontEnd/wind.mp3",
  // curtain: "/static/audio/sounds/frontEnd/curtain.mp3",
  // ... add other audio sources as needed
};

const props = withDefaults(defineProps<FxPlayerProps>(), {});

const audioStore = useAudioStore();

const getCurrentFx = computed(() => audioStore.getCurrentFx);
const getIsMute = computed(() => audioStore.getIsMute);
const { setCurrentFx } = useAudioStore();

const playAudio = () => {
  const audio = audioPlayer.value as HTMLAudioElement;
  const currentFx = getCurrentFx.value;
  const src = audioSources[currentFx];

  if (src) {
    audio.src = src;
    audio.play();
  }
};

const onAudioEnded = () => {
  setCurrentFx(null);
};

watch(
  () => getCurrentFx.value,
  (value) => {
    value && !getIsMute.value ? playAudio() : null;
  },
);
</script>

<style lang="scss" scoped></style>
