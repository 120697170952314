/**
 * Generates a random number between min (inclusive) and max (inclusive).
 * @param {number} min - The minimum value.
 * @param {number} max - The maximum value.
 * @returns {number} - A random number between min and max.
 */
export const getRandomNumber = (min: number, max: number): number => {
  return Math.floor(Math.random() * (max - min + 1)) + min;
};
export const getRandomNumberFull = (min: number, max: number): number => {
  return Math.random() * (max - min) + min;
};

export const getRandomRotation = () => {
  // Randomly decide between negative or positive range
  const isNegative = Math.random() < 0.5;

  if (isNegative) {
    // Generate number between -5 and -2
    return Math.random() * -3 - 2;
  } else {
    // Generate number between 2 and 5
    return Math.random() * 3 + 2;
  }
};

/**
 * Generates a random number between 1 and 10000.
 * @returns {number} A random number between 1 and 10000.
 */
// export const getRandomNumber = (): number => {
//   return Math.floor(Math.random() * 10000) + 1;
// };
