<template>
  <span
    v-if="svg"
    class="queso-icon"
    :class="iconClasses"
    aria-hidden="true"
    v-html="svg"
  ></span>
  <span
    v-else
    class="queso-icon"
    :class="iconClasses"
    aria-hidden="true"
  >
    <slot v-bind="{ ...props, width, rotation, iconID }">
      <svg>
        <use :href="iconID" />
      </svg>
    </slot>
  </span>
</template>

<script setup lang="ts">
import { computed } from "vue";

import type { IconBaseProps } from "./types";

const props = withDefaults(defineProps<IconBaseProps>(), {
  name: "chevron",
  prefix: "icon",
  size: 2,
  sizeUnit: "rem",
  rotation: 0,
});

const iconID = computed<string>(() => {
  const prefix = props.prefix ? `${props.prefix}-` : "";
  return `#${prefix}${props.name}`;
});

const iconClasses = computed(() => {
  let classes: string[] = ["c-icon"];

  if (props.name) classes.push(`-${props.name}`);

  return classes;
});

// Visual
const width = computed<string>(() => `${props.size}${props.sizeUnit}`);
const rotation = computed<string>(() => `${props.rotation}deg`);
</script>

<style lang="scss" scoped>
.c-icon {
  display: var(--icon-display, inline-flex);
  align-items: var(--icon-align, center);
  justify-content: var(--icon-justify, center);
  vertical-align: var(--icon-vertical-align, middle);
  flex-shrink: var(--icon-flex-shrink, 0);

  svg {
    display: block;
    width: var(--icon-width, v-bind("width"));
    height: calc(var(--icon-height, var(--icon-width, v-bind("width"))) * var(--icon-ratio, 1));
    fill: var(--icon-fill, currentColor);
    transform: rotate(var(--icon-rotation, v-bind("rotation")));

  }
}
</style>
