import type { DirectiveBinding, ObjectDirective } from "vue";
import { warn } from "vue";

import { useLoadingStore } from "@/stores/loading";

/**
 * Interface for the options that can be passed to the directive.
 * @interface
 * @property {number} [start] - The position in the viewport where the directive should start updating. Defaults to 1 (bottom of the viewport).
 * @property {number} [end] - The position in the viewport where the directive should stop updating. Defaults to 0 (top of the viewport).
 * @property {number} [startDom] - The position in the DOM where the directive should start updating. Defaults to 0 (top of the DOM).
 * @property {(info: ViewDirectiveCallbackInfo) => void} [callback] - A function that is called with the visibility information of the element.
 * @property {boolean} [once] - Whether the directive should stop updating after the element is fully visible. Defaults to false.
 * @property {boolean} [isDebug] - Whether to show visual indicators for the start and end positions. Defaults to false.
 * @property {string} [name] - The name of the directive. Will be used for the debug markers.
 */
interface DirectiveOptions {
  classPrefix?: string;
  start?: number;
  end?: number;
  startDom?: number;
  once?: boolean;
  callback?: (info: ViewDirectiveCallbackInfo) => void;
  isDebug?: boolean;
  name?: string;
}

/**
 * Interface for the visibility information that is passed to the callback function.
 * @interface
 * @property {number} progress - The visibility of the element as a number between 0 and 1.
 * @property {boolean} isVisible - Whether the element is fully visible.
 * @property {boolean} isSneekPeek - Whether the element is beginning to be visible.
 */
export interface ViewDirectiveCallbackInfo {
  progress: number;
  isVisible: boolean;
  isSneekPeek: boolean;
}

interface MarkerStyles {
  readonly base: string;
  readonly label: string;
}

interface MarkerConfig {
  color: string;
  label: string;
  position: number;
  type: "component" | "viewport";
}

const STYLES: MarkerStyles = {
  base: `
      left: 0;
      right: 0;
      height: 2px;
      pointer-events: none;
      z-index: 9999;
      display: flex;
      align-items: center;
  `,
  label: `
      background: rgba(0,0,0,0.7);
      color: white;
      font-size: 10px;
      padding: 2px 4px;
      margin-left: 4px;
      border-radius: 2px;
  `,
} as const;

const createMarker = ({
  color,
  label,
  position,
  type,
}: MarkerConfig): HTMLDivElement => {
  const marker = document.createElement("div");
  const positionType = type === "component" ? "absolute" : "fixed";

  marker.setAttribute(
    "style",
    `
      position: ${positionType};
      ${STYLES.base}
      top: ${position}px;
      background: ${color};
      `,
  );

  marker.innerHTML = `<span style="${STYLES.label}">${label}</span>`;
  return marker;
};

const calculateProgress = (
  rect: DOMRect,
  startPosition: number,
  endPosition: number,
  startDom: number,
): number => {
  // Calculate the offset from the top of the component where animation should start
  const domOffset = rect.height * startDom;

  // Adjust the rect.top by the domOffset to start animation from that point
  const adjustedTop = rect.top + domOffset;

  const rawProgress = Math.max(
    0,
    Math.min(1, (adjustedTop - endPosition) / (startPosition - endPosition)),
  );
  return 1 - rawProgress;
};

/**
 * Vue directive that calculates the visibility of an element in the viewport and calls a callback function with the visibility information as a parameter.
 * The visibility is also set as a CSS variable on the element.
 * If the `once` option is set to true, the directive will stop updating after the element is fully visible.
 * @type {ObjectDirective<HTMLElement, DirectiveOptions>}
 *
 * @example
 * // In your Vue component
 * import type { ViewDirectiveCallbackInfo } from "@/directives/view";
 *
 * // In your Vue template
 * <div v-view="{start: 0.5, end: 0.2, once: true, callback: (info) => { myVariable = info.progress; isVisible = info.isVisible; isSneekPeek = info.isSneekPeek; }}"></div>
 *
 * // This will start updating when the element's top edge is in the middle of the viewport and stop updating when it's 20% from the top.
 * // The visibility information of the element will be stored in `myVariable`, `isVisible`, and `isSneekPeek`.
 * // The directive will stop updating after the element is fully visible.
 */
export const viewDirective: ObjectDirective<HTMLElement, DirectiveOptions> = {
  mounted(el: HTMLElement, binding: DirectiveBinding<DirectiveOptions>) {
    const options = {
      classPrefix: binding.value?.classPrefix ?? "v-view",
      start: binding.value?.start ?? 1,
      end: binding.value?.end ?? 0,
      startDom: binding.value?.startDom ?? 0,
      once: binding.value?.once,
      callback: binding.value?.callback,
      isDebug: binding.value?.isDebug ?? false,
      name: binding.value?.name,
    };

    el.classList.add(options.classPrefix);

    if (options.start === options.end) {
      warn("Start and end positions are equal. The directive will not update.");
      return;
    }

    const updateProgress = (): void => {
      if (useLoadingStore().isLoadingVisible) {
        return;
      }

      const rect = el.getBoundingClientRect();
      const viewportHeight = window.innerHeight;
      const startPosition = viewportHeight * options.start;
      const endPosition = viewportHeight * options.end;

      const progress = calculateProgress(
        rect,
        startPosition,
        endPosition,
        options.startDom,
      );

      el.style.setProperty("--progress", progress.toString());

      const isVisible = progress === 1;
      const isSneekPeek = progress > 0.1;

      if (isVisible && options.once) {
        window.removeEventListener("scroll", updateProgress);
        window.removeEventListener("resize", updateProgress);
        el.classList.add(`${options.classPrefix}-done`);
      }

      options.callback?.({ progress, isVisible, isSneekPeek });
    };

    if (options.isDebug) {
      const elementHeight = el.offsetHeight;

      const markers = {
        start: createMarker({
          color: "rgba(0, 255, 0, 0.5)",
          label: options.name ? `${options.name} start` : "start",
          position: elementHeight * options.startDom,
          type: "component",
        }),
        end: createMarker({
          color: "rgba(255, 0, 0, 0.5)",
          label: options.name ? `${options.name} end` : "end",
          position: elementHeight,
          type: "component",
        }),
        viewportStart: createMarker({
          color: "rgba(0, 0, 255, 0.5)",
          label: options.name
            ? `${options.name} start trigger`
            : "start trigger",
          position: options.start * 100 * (window.innerHeight / 100),
          type: "viewport",
        }),
        viewportEnd: createMarker({
          color: "rgba(255, 165, 0, 0.5)",
          label: options.name ? `${options.name} end trigger` : "end trigger",
          position: options.end * 100 * (window.innerHeight / 100),
          type: "viewport",
        }),
      };

      Object.entries(markers).forEach(([key, marker]) => {
        marker.classList.add(`${options.classPrefix}-${key}-marker`);
        if (key.includes("viewport")) {
          document.body.appendChild(marker);
        } else {
          if (window.getComputedStyle(el).position === "static") {
            el.style.position = "relative";
          }
          el.appendChild(marker);
        }
      });

      el._markers = markers;
    }

    updateProgress();
    window.addEventListener("scroll", updateProgress);
    window.addEventListener("resize", updateProgress);
    el._updateProgress = updateProgress;
  },

  unmounted(el: HTMLElement) {
    window.removeEventListener("scroll", el._updateProgress ?? (() => {}));
    window.removeEventListener("resize", el._updateProgress ?? (() => {}));

    el._markers &&
      Object.values(el._markers).forEach((marker) => marker.remove());
  },
};

// Extend the HTMLElement interface to include _updateProgress and _observer
declare global {
  interface HTMLElement {
    _updateProgress?: () => void;
    _markers?: Record<
      "start" | "end" | "viewportStart" | "viewportEnd",
      HTMLDivElement
    >;
  }
}
