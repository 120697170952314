<template>
  <div
    class="button-audio"
    :class="cssClasses"
  >
    <div class="button-audio__inner">
      <button-base
        @keydown.space.prevent
        @keydown.enter.prevent
        @keydown.enter="toggleAudio"
        @keydown.space="toggleAudio"
        @click="handleClick"
        ref="buttonBaseRef"
        :aria-label="audioStore.isMute ? 'Unmute' : 'Mute'"
        :aria-checked="!audioStore.isMute"
        role="switch"
        :alt="audioStore.isMute ? 'Unmute' : 'Mute'"
      >
        <div
          class="button-audio__mute"
          ref="audioMute"
        >
          <icon-base
            name="music"
            :size="2.5"
          ></icon-base>
        </div>
        <button-audio-animation :is-active="!audioStore.isMute"></button-audio-animation>
      </button-base>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from "vue";
import { useEventListener, useDebounceFn } from "@vueuse/core";
import { useAudioStore } from "@/stores/audio";
import { useIdleStore } from "@/stores/idle";

import ButtonBase from "../ButtonBase/ButtonBase.vue";
import IconBase from "../IconBase/IconBase.vue";
import ButtonAudioAnimation from "./components/ButtonAudioAnimation";

import type { ButtonAudioProps } from "./types";

const props = withDefaults(defineProps<ButtonAudioProps>(), {});

const audioStore = useAudioStore();
const idleStore = useIdleStore();
const { toggleIsMute, isMute } = audioStore;

const audioMute = ref<HTMLElement | null>(null);
const buttonBaseRef = ref<InstanceType<typeof ButtonBase> | null>(null);
const isFirstVisit = computed(() => idleStore.getIsFirstVisit);

const handleClick = (event: MouseEvent) => {
  // Only handle mouse clicks (detail > 0) or non-space keyboard events
  // Space key is already prevented with @keydown.space.prevent

  event.detail > 0 && toggleAudio();
};

const toggleAudio = useDebounceFn(() => {
  toggleIsMute(!audioStore.isMute, true);
}, 300);

useEventListener(document, "keydown", (e) => {
  if (
    e.key === "ArrowUp" ||
    e.key === "ArrowDown" ||
    e.key === "ArrowLeft" ||
    e.key === "ArrowRight"
  ) {
    // Get the actual DOM element
    const buttonElement = buttonBaseRef.value?.$el;
    const activeElement = document.activeElement;

    // Only blur if our button or one of its children has focus
    if (
      buttonElement &&
      (activeElement === buttonElement || buttonElement.contains(activeElement))
    ) {
      (activeElement as HTMLElement).blur();
    }
  }
});

const cssClasses = computed(() => [
  audioStore.isMute && "-mute",
  isFirstVisit.value && "-first-visit",
]);
</script>

<style lang="scss" scoped>
.button-audio {
  --button-audio-top: var(--spacer-sm);
  --button-audio-right: var(--spacer-sm);
  --button-audio-mute-opacity: 0;
  --button-audio-width: 10rem;
  --button-audio-height: 5rem;
  --button-audio-mute-color: var(--color-shadow);
  --button-audio-opacity: 1;

  position: fixed;
  top: var(--button-audio-top);
  right: var(--button-audio-right);
  opacity: var(--button-audio-opacity);
  z-index: var(--z-audio);

  transition: opacity 1s ease;

  @include min("md") {
    --button-audio-top: var(--spacer-md);
    --button-audio-right: var(--spacer-md);
  }

  &:hover,
  &:focus {
    --button-audio-mute-color: var(--color-driftwood);
  }

  &.-mute {
    --button-audio-mute-opacity: 1;
    --button-audio-width: 3rem;
    --button-audio-height: 3rem;
  }

  &.-first-visit {
    --button-audio-opacity: 0;
    pointer-events: none;
  }

  &__inner {
    position: relative;
  }

  &__mute {
    transition:
      1s ease opacity,
      0.5s ease color;
    opacity: var(--button-audio-mute-opacity);
    color: var(--button-audio-mute-color);
  }

  :deep(.c-button-base) {
    width: var(--button-audio-width);
    height: var(--button-audio-height);
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
  }

  :deep(.c-button-base:focus) {
    --button-audio-mute-color: var(--color-driftwood);
  }
}
</style>
