import { browserName, osName, isMobile, isTablet } from "mobile-device-detect";

const userContext = {
  browser: {
    name: browserName.toLowerCase().replace(" ", "-"),
    os: osName.toLowerCase().replace(" ", "-"),
  },
  isMobile,
  isTablet,
};

export default userContext;
