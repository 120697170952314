<template>
  <div class="loader-progress" :class="cssClasses">
    <div class="loader-progress__icon">
      <div class="loader-progress__icon__watch">
        <icon-base name="pocket-watch" :size="6"></icon-base>
      </div>
      <div class="loader-progress__icon__button">
        <icon-base name="pocket-watch-button" :size="2"></icon-base>
      </div>
      <div class="loader-progress__icon__arrow">
        <icon-base name="pocket-watch-arrow" :size="2"></icon-base>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import type { TheLoaderProgressProps } from "./types";

import IconBase from "@/components/IconBase";

const props = withDefaults(defineProps<TheLoaderProgressProps>(), {});

const arrowAngle = computed(() => props.progress * 3.6);

const visual = computed(() => ({
  rotation: `${arrowAngle.value}deg`,
}));

const cssClasses = computed(() => [
  props.isReseting && "-reseting",
  props.isVisible && "-visible",
]);
</script>

<style lang="scss" scoped>
.loader-progress {
  $self: &;
  --loader-progress-left: var(--spacer-sm);
  --loader-progress-arrow-angle: v-bind("visual.rotation");
  --loader-progress-opacity: 0;
  position: absolute;
  top: calc(var(--loader-progress-left) + var(--spacer-2xs));
  left: var(--loader-progress-left);

  transform: rotate(-30deg);
  opacity: var(--loader-progress-opacity);
  transition: opacity 1s ease;
  z-index: 999999;

  &.-visible {
    --loader-progress-opacity: 1;
  }

  @include min("md") {
    --loader-progress-left: var(--spacer-md);
  }

  &__icon {
    position: relative;
    aspect-ratio: 1/1;
    width: 6rem;

    &__button {
      position: absolute;
      top: 0rem;
      left: 50%;
      transform: translate(-50%, -90%);
    }

    &__arrow {
      position: absolute;
      top: 1.1rem;
      left: 3rem;

      transform: translate3d(-50%, 0, 0)
        rotate(var(--loader-progress-arrow-angle));
      transform-origin: center 2rem;
      transition: transform 0.3s linear;
      will-change: transform;
      backface-visibility: hidden;
      perspective: 1000;
      -webkit-transform-style: preserve-3d;

      #{$self}.-reseting & {
        transition: transform 0s linear;
      }

      :deep(svg) {
        width: 1rem;
      }
    }
  }
}
</style>
