<template>
  <div
    class="title-decorative"
    ref="target"
    :class="cssClasses"
    v-view="{
      once: true,
      start: 0.9,
      callback: (info: any) => {
        isAnimationAuto && info.isSneekPeek
          ? toggleIsAnimationStarted(true)
          : null;
        // toggleIsAnimationStarted(true);
      },
    }"
  >
    <!-- v-view="{
      once: true,
      start: 0.9,
      callback: (info: any) => {
        isAnimationAuto ? toggleIsAnimationStarted(true) : null;
        // toggleIsAnimationStarted(true);
      },
    }" -->
    <!-- v-view="{
    once: true,
    start: 0.9,
    callback: (info: any) => {
      isAnimationAuto ? toggleIsAnimationStarted(true) : null;
    },
  }" -->
    <!-- v-view="{
            once: true,
            start: 0.9,
            callback: (info: any) => {
              toggleIsVisible(info.isSneekPeek);
            },
        }"
         -->
    <div class="title-decorative__inner">
      <decor-animated
        v-if="isDecorVisible"
        :width="svgInfo.width"
        :height="svgInfo.height"
        :view-box="svgInfo.viewBox"
        :path="svgInfo.path"
        :is-playing="isAnimationStarted"
        :is-visible="false"
      ></decor-animated>

      <span>{{ $t(body) }}</span>

      <decor-animated
        v-if="isDecorVisible"
        :width="svgInfo.width"
        :height="svgInfo.height"
        :view-box="svgInfo.viewBox"
        :path="svgInfo.path"
        :rotation="180"
        :is-playing="isAnimationStarted"
      ></decor-animated>
    </div>
  </div>
</template>
<script setup lang="ts">
import { ref, watch, computed, onMounted } from "vue";

import DecorAnimated from "@/components/DecorAnimated/DecorAnimated.vue";

import type { TitleDecorativeProps } from "./types";

import svgInfo from "./svgInfo.json";

const emit = defineEmits(["animation-completed"]);

const props = withDefaults(defineProps<TitleDecorativeProps>(), {
  body: null,
  isPlaying: false,
  isVisible: true,
  isDecorVisible: true,
  isAnimationAuto: false,
  size: "large",
  isEmitAnimationCompleted: false,
  isForceEndAnimation: false,
});

const isAnimationStarted = ref<boolean>(false);
const target = ref<HTMLBodyElement>(null);

const toggleIsAnimationStarted = (bool: boolean) => {
  isAnimationStarted.value = bool;
};

const animationCompleted = () => {
  setTimeout(() => {
    emit("animation-completed");
  }, 500);
};

watch(
  () => props.isPlaying,
  () => {
    // props.isPlaying && isHeroAnimationEnded && !isScrolling.value // leave it here if I found other bugs
    props.isPlaying ? playAnimation() : toggleIsAnimationStarted(false);
  },
);

const playAnimation = () => {
  props.isEmitAnimationCompleted && !isAnimationStarted.value
    ? animationCompleted()
    : null;
  toggleIsAnimationStarted(true);
};

watch(
  () => props.isForceEndAnimation,
  () => {
    props.isForceEndAnimation && playAnimation();
  },
);

onMounted(() => {
  props.isForceEndAnimation && playAnimation();
});

const cssClasses = computed(() => [
  props.size && `-${props.size}`,
  (isAnimationStarted.value || props.isVisible) && "-visible",
]);
</script>
<style lang="scss" scoped>
.title-decorative {
  $self: &;
  --title-decorative-opacity: 0;
  width: 100%;
  text-align: center;
  color: var(--color-driftwood);

  &.-visible {
    --title-decorative-opacity: 1;
  }

  &.-medium {
    margin-bottom: var(--spacer-md);
  }

  &__inner {
    @include stack(
      "horizontal",
      "center",
      "center",
      $gap: var(--spacer-md),
      $wrap: wrap,
      $var-prefix: "stack"
    );

    span {
      font-style: italic;
      opacity: var(--title-decorative-opacity);
      transition: opacity 2s ease;

      #{$self}.-medium & {
        @include h("h6");
      }

      #{$self}.-large & {
        @include h("h4");
      }
    }

    :deep(.decor-animated) {
      margin-top: -0.5rem;
    }
  }
}
</style>
