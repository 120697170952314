<template>
  <!-- <div v-if="isLoadingVisible" class="loader" :class="cssClasses"> -->
  <div class="loader" :class="cssClasses">
    <div class="loader__inner">
      <the-loader-progress
        :is-reseting="isReseting"
        :is-visible="isLoaderAppCopyVisible"
        :progress="Math.floor(currentProgress)"
      ></the-loader-progress>
      <the-loader-body :is-visible="isLoaderAppCopyVisible"></the-loader-body>
      <div v-if="isTutorialVisible" class="loader__inner__tutorial">
        <tutorial-panel></tutorial-panel>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, watch, ref, onMounted, onUnmounted, shallowRef } from "vue";
import { useRoute } from "vue-router";
import { useLoadingStore } from "@/stores/loading";
import { useIdleStore } from "@/stores/idle";

import TutorialPanel from "@/components/TutorialPanel";
import TheLoaderBody from "./components/TheLoaderBody";
import TheLoaderProgress from "./components/TheLoaderProgress";

import type { TheLoaderProps } from "./types";

const props = withDefaults(defineProps<TheLoaderProps>(), {});
const route = useRoute();

const loadingStore = useLoadingStore();
const {
  setAnimationCompleted,
  resetAnimationCompleted,
  resetProgress,
  disabledLoadingProgress,
} = loadingStore;
const idleStore = useIdleStore();

const currentRouteName = computed(() => route.name);

const endLoadingWithDelayTimeout = ref<NodeJS.Timeout | null>(null);

// const WAIT_TIME_TO_ANIMATE_HOME = 2200;
const WAIT_TIME_TO_ANIMATE_DEFAULT = 2000;
const MAX_PROGRESS = 95;
const MAX_PROGRESS_RESET = 100;

const animationFrameId = shallowRef<number>(0);
const isLoaderAppCopyVisible = shallowRef<boolean>(false);
const currentProgress = shallowRef<number>(0);
const isReseting = ref<boolean>(false);
const isResetingTimeout = shallowRef<number | null>(null);
const isResetingTimeoutOut = shallowRef<number | null>(null);
const isAnimationStartable = ref<boolean>(false);

const isPageHome = computed(() => currentRouteName.value === "home");
const isLoadingVisible = computed(() => loadingStore.isLoadingVisible);
const getIsAnimationCompleted = computed(
  () => loadingStore.getIsAnimationCompleted
);

const isTutorialVisible = computed(() => loadingStore.isTutorialVisible);
const progress = computed(() => loadingStore.progress);
const getIsIdleVisible = computed(() => idleStore.getIsIdleVisible);

const isIdleVisible = computed(
  () => getIsIdleVisible.value && isPageHome.value
);

const runAnimation = () => {
  // resetProgress();
  isReseting.value = false;

  // Ensure progress is fully reset before starting animation
  // setTimeout(() => {
  // nextTick(() => {
  toggleIsAnimationStartable(true);
  toggleLoaderAppCopyVisible(true);

  endLoadingWithDelay();
  startProgressAnimation();
  // });
  // }, 100); // Small delay to ensure store update is complete
};

// watch(progress, (newVal) => {
//   console.log("progress", newVal, isAnimationStartable.value);

//   if (newVal === 0 && isAnimationStartable.value) {
//     startProgressAnimation();
//   }
// });

const toggleIsAnimationStartable = (value: boolean) => {
  isAnimationStartable.value = value;
};

const startProgressAnimation = () => {
  // console.log(
  //   "startProgressAnimation",
  //   isAnimationStartable.value,
  //   progress.value
  // );
  toggleIsAnimationStartable(false);
  clearResetingTimeout();

  resetAnimationCompleted();

  currentProgress.value = 1;

  let lastUpdate = 0 as number;
  let shouldRespectTimeout = true as boolean;

  const animate = (timestamp: number) => {
    // console.log("animate", currentProgress.value, progress.value);
    // Only process animation if tab is visible

    if (document.hidden) {
      animationFrameId.value = requestAnimationFrame(animate);
      return;
    }

    const delta = timestamp - lastUpdate;
    // Use integer comparison instead of float
    if (delta >= 17) {
      if (
        loadingStore.isLoadingProgressEnabled &&
        progress.value > MAX_PROGRESS
      ) {
        currentProgress.value = shouldRespectTimeout
          ? Math.min(progress.value, MAX_PROGRESS)
          : progress.value;
      } else if (!loadingStore.isLoadingProgressEnabled) {
        currentProgress.value = currentProgress.value + 0.1;
      } else if (currentProgress.value < MAX_PROGRESS) {
        currentProgress.value = calculateCurrentProgress();
      }

      lastUpdate = timestamp;

      // Only reset after the normal animation delay has completed
      if (
        currentProgress.value >= MAX_PROGRESS_RESET &&
        !shouldRespectTimeout &&
        loadingStore.isLoadingProgressEnabled
      ) {
        resetCurrentProgressWithDelay();
        return;
      }
    }

    animationFrameId.value = requestAnimationFrame(animate);
  };

  // Cancel any existing animation before starting new one
  if (animationFrameId.value) {
    cancelAnimationFrame(animationFrameId.value);
  }

  animationFrameId.value = requestAnimationFrame(animate);

  // Force the progress to go through the normal animation delay before resetting
  setTimeout(() => {
    shouldRespectTimeout = false;
  }, WAIT_TIME_TO_ANIMATE_DEFAULT);
};

const calculateCurrentProgress = () => {
  return progress.value > currentProgress.value
    ? progress.value
    : currentProgress.value + 0.1;
};

const resetCurrentProgressWithDelay = () => {
  // console.log("resetCurrentProgressWithDelay");

  if (animationFrameId.value) {
    cancelAnimationFrame(animationFrameId.value);
    animationFrameId.value = 0;
  }

  // resetProgress();
  isResetingTimeout.value = window.setTimeout(() => {
    isReseting.value = true;
    currentProgress.value = 0;
    isResetingTimeoutOut.value = window.setTimeout(() => {
      isReseting.value = false;
      toggleIsAnimationStartable(true);

      disabledLoadingProgress();
      clearResetingTimeout();
    }, 500);
  }, 500);
};

const clearResetingTimeout = () => {
  isResetingTimeout.value && clearTimeout(isResetingTimeout.value);
  isResetingTimeoutOut.value && clearTimeout(isResetingTimeoutOut.value);
};

const endLoadingWithDelay = () => {
  clearTimeoutEndLoadingWithDelay();
  endLoadingWithDelayTimeout.value = setTimeout(
    () => {
      setAnimationCompleted();
    },
    WAIT_TIME_TO_ANIMATE_DEFAULT
    // waitTimeToAnimateDefault.value,
    // isPageHome.value
    //   ? waitTimeToAnimateHome.value
    //   : waitTimeToAnimateDefault.value,
  );
};

const clearTimeoutEndLoadingWithDelay = () => {
  endLoadingWithDelayTimeout.value
    ? (clearTimeout(endLoadingWithDelayTimeout.value),
      (endLoadingWithDelayTimeout.value = null))
    : null;
};

const toggleLoaderAppCopyVisible = (value: boolean) => {
  isLoaderAppCopyVisible.value = value;
};

const cssClasses = computed(() => [
  !getIsIdleVisible.value && isLoadingVisible.value && "-visible",
  progress.value === 100 && getIsAnimationCompleted.value && "-fade-out",
]);

onMounted(() => {
  !isIdleVisible.value ? runAnimation() : null;
});

watch(isLoadingVisible, (newVal) => {
  if (newVal) {
    setTimeout(() => {
      runAnimation();
    }, 100);
  } else {
    toggleLoaderAppCopyVisible(false);
  }
});

// Clean up on component unmount
onUnmounted(() => {
  resetLoaderStore();

  if (animationFrameId.value) {
    cancelAnimationFrame(animationFrameId.value);
  }
  isResetingTimeout.value && clearTimeout(isResetingTimeout.value);
  isResetingTimeoutOut.value && clearTimeout(isResetingTimeoutOut.value);
});

const resetLoaderStore = () => {
  resetAnimationCompleted();
  resetProgress();
  loadingStore.isLoadingVisible = false;
  loadingStore.isTutorialVisible = false;
};
</script>

<style lang="scss" scoped>
.loader {
  --loader-opacity: 0;
  --loader-pointer-events: none;
  --loader-inner-transform-y: 0;
  --loader-inner-scale: 1;
  --loader-inner-blur: 0rem;
  --loader-inner-opacity: 1;
  --loader-inner-transition:
    transform 2s ease-in, opacity 1.5s ease-in, filter 1s ease-in;
  position: fixed;
  top: 0rem;
  right: 0rem;
  bottom: 0rem;
  left: 0rem;
  width: 100%;
  z-index: var(--z-loader);
  opacity: var(--loader-opacity);
  // opacity: 0;
  pointer-events: var(--loader-pointer-events);
  transition: opacity 1s ease;

  &.-visible {
    --loader-inner-opacity: 1;
    --loader-opacity: 1;
    --loader-pointer-events: auto;
  }

  &.-fade-out {
    --loader-inner-opacity: 0;
    --loader-pointer-events: none;
  }

  &__inner {
    @include stack(
      "vertical",
      "center",
      "center",
      $gap: var(--spacer-md),
      $wrap: wrap,
      $var-prefix: "stack"
    );
    position: relative;
    height: 100%;
    width: 100%;
    background-color: var(--color-cod-gray);

    :deep(.loader-body) {
      opacity: var(--loader-inner-opacity);
      transition: opacity 1s ease;
    }

    &__tutorial {
      @include stack(
        "horizontal",
        "center",
        "bottom",
        $gap: var(--spacer-md),
        $wrap: wrap,
        $var-prefix: "stack"
      );
      position: absolute;
      bottom: var(--spacer-md);
      left: 0;
      width: 100%;
    }
  }
}
</style>
