import { ref } from "vue";
import { defineStore } from "pinia";
import type { LoadingState } from "./loading.types";
import { IS_TRANSITION_DEBUG } from "@/constant/debug";

const VIDEO_TO_LOAD = {
  MSM: 2,
  LEAP: 2,
};

const isLoading = ref<boolean>(false);

export const useLoadingStore = defineStore("loading", {
  state: (): LoadingState => ({
    // isLoading: false,
    isLoadingVisible: true,
    progress: 0,
    isAnimationCompleted: false,
    isTutorialVisible: false,
    loadedVideoCount: 0,
    areVideosLoaded: true,
    isLoadingProgressEnabled: false,
  }),

  getters: {
    // getIsLoading: (state) => state.isLoading, // private. Not nesseare
    getIsAnimationCompleted: (state) =>
      state.isAnimationCompleted && state.areVideosLoaded,
    getIsDebug: (state) => IS_TRANSITION_DEBUG && import.meta.env.DEV,
  },

  actions: {
    showLoading() {
      if (isLoading.value) return;
      isLoading.value = true;
      this.resetAnimationCompleted();
      this.resetProgress();

      // issues with nextTick not fast enough on ios
      setTimeout(() => {
        this.isLoadingVisible = true;
        IS_TRANSITION_DEBUG && import.meta.env.DEV
          ? this.setAnimationCompleted()
          : null;
        isLoading.value = false;
      }, 300);
    },

    hideLoading() {
      this.isLoadingVisible = false;
      // console.log("hideLoading");
    },
    displayLoading() {
      this.isLoadingVisible = true;
    },
    setProgress(progress: number) {
      // console.log("setProgress", progress);
      this.progress = progress;
    },
    setAnimationCompleted() {
      this.isAnimationCompleted = true;
    },
    resetAnimationCompleted() {
      this.isAnimationCompleted = false;
    },
    showTutorial() {
      this.isTutorialVisible = true;
    },
    hideTutorial() {
      this.isTutorialVisible = false;
    },
    resetProgress() {
      // console.log("resetProgress");

      this.progress = 0;
    },
    enableLoadingProgress() {
      // console.log("enableLoadingProgress");

      // visual progress bar
      this.isLoadingProgressEnabled = true;
    },
    disabledLoadingProgress() {
      // console.log("disabledLoadingProgress");
      // visual progress bar
      this.isLoadingProgressEnabled = false;
    },

    videoLoaded(page: string) {
      this.loadedVideoCount += 1;
      this.checkIfVideosAreLoaded(page);
    },
    checkIfVideosAreLoaded(page: string) {
      this.areVideosLoaded = this.loadedVideoCount >= VIDEO_TO_LOAD[page];
    },
    resetVideoLoaded() {
      this.loadedVideoCount = 0;
      this.areVideosLoaded = false;
    },
    overwriteAreVideoLoaded(bool: boolean) {
      // used for everypage when videos are not required to load
      this.areVideosLoaded = bool;
    },
  },
});
