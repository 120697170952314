<template>
  <div class="button-audio-animation">
    <ul class="button-audio-animation__inner">
      <li
        class="button-audio-animation__inner__list"
        v-for="index in 7"
        :key="`audio-${index}`"
        ref="audioItems"
      >
        <icon-base name="music"></icon-base>
      </li>
    </ul>
  </div>
</template>

<script setup lang="ts">
import { onMounted, ref, nextTick, watch } from "vue";
import type { ButtonAudioAnimationProps } from "./types";

import IconBase from "@/components/IconBase/IconBase.vue";

import { gsap } from "gsap";

const props = withDefaults(defineProps<ButtonAudioAnimationProps>(), {
  isActive: false,
});

const DURATION = 4;
const DURATION_FADEOUT = DURATION - 2;
const audioItems = ref<HTMLBaseElement[]>([]);
const listOfTimelines = ref([]);

const initTimeline = () => {
  audioItems.value.forEach((item, index) => {
    const timeline = gsap.timeline({
      paused: true,
      repeat: -1,
      onComplete: () => {
        listOfTimelines.value[index].isRunning = false;
      },
    });

    const randomY = Math.random() * 100 - 50; // Random Y direction between -25 and 25
    const randomRotation = Math.random() * 100 - 50; // Random rotation between -10 and 10 degrees
    timeline
      .to(
        item,
        {
          scale: 2,
          opacity: 1,
          x: -100,
          y: randomY,
          rotation: randomRotation,
          duration: DURATION,
          ease: "linear",
          onStart: () => {
            listOfTimelines.value[index].isRunning = true;
          },
        },
        index * 0.91,
      )
      .to(
        item,
        {
          filter: "blur(5px)",
          opacity: 0,
          duration: DURATION_FADEOUT,
          ease: "power2.out",
        },
        DURATION_FADEOUT + index * 0.91,
      );
    listOfTimelines.value.push({ timeline: timeline, isRunning: false });
  });
};

const runAnimation = () => {
  listOfTimelines.value.forEach((timeline) => {
    timeline.timeline.progress(0);
    timeline.timeline.repeat(-1);
    timeline.timeline.play();
  });
};

const stopAnimation = () => {
  listOfTimelines.value.forEach((timeline) => {
    timeline.isRunning
      ? timeline.timeline.repeat(0)
      : timeline.timeline.pause();
  });
};

watch(
  () => props.isActive,
  (bool) => {
    bool ? runAnimation() : stopAnimation();
  },
);

onMounted(() => {
  nextTick(() => {
    audioItems.value = audioItems.value.map((item) => item);
    initTimeline();
  });
});
</script>

<style lang="scss" scoped>
.button-audio-animation {
  position: absolute;
  top: 0rem;
  right: 0rem;
  color: var(--color-driftwood);

  &__inner {
    &__list {
      position: absolute;
      top: 0rem;
      right: 0rem;
      transform: scale(0);
      opacity: 1;
    }
  }
}
</style>
