// @ts-nocheck
// import messages from "@intlify/vite-plugin-vue-i18n/messages";
import en from "./locales/en.json";

import { createI18n } from "vue-i18n";

function loadLocaleMessages() {
  const locales = [{ en: en }];
  const messages = {};
  locales.forEach((lang) => {
    const key = Object.keys(lang);
    messages[key] = lang[key];
  });
  return messages;
}
const i18n = createI18n({
  legacy: false,
  locale: localStorage.locale || "en",
  messages: loadLocaleMessages(),
  globalInjection: true,
  warnHtmlMessage: false,
});

const $t = i18n.global.t;

export { i18n, $t };
