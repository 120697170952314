<template>
  <div :class="cssClasses">
    <cursor-trail v-if="!(userContext.isMobile || userContext.isTablet)" />
    <button-audio />
    <the-loader></the-loader>
    <audio-player />
    <fx-player></fx-player>
    <main>
      <RouterView />
    </main>
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, watch } from "vue";
import { useDocumentVisibility } from "@vueuse/core";

import { RouterView } from "vue-router";

import TheLoader from "@/components/TheLoader/TheLoader.vue";
import ButtonAudio from "@/components/ButtonAudio/ButtonAudio.vue";
import AudioPlayer from "./components/AudioPlayer/AudioPlayer.vue";
import FxPlayer from "@/components/FxPlayer/FxPlayer.vue";
import userContext from "@/utils/userContext/userContext";
import CursorTrail from "./components/CursorTrail";

import { useGameStore } from "@/stores/game";

import "@/assets/scss/global.scss";

const cssClasses = computed(() => {
  return {
    [`-${userContext.browser.name}`]: userContext.browser.name,
    [`-${userContext.browser.os}`]: userContext.browser.os,
    "-mobile": userContext.isMobile,
    "-tablet": userContext.isTablet,
  };
});

const gameStore = useGameStore();

const updateCompletedGameFromCookies = () => {
  gameStore.updateCompletedGameFromCookie();
};

// Track document visibility
const visibility = useDocumentVisibility();

// Function to update favicon based on visibility
const updateFavicon = (isVisible: boolean) => {
  // Remove all existing favicon links first
  document
    .querySelectorAll('link[rel*="icon"]')
    .forEach((link) => link.remove());
  document
    .querySelectorAll('link[rel="apple-touch-icon"]')
    .forEach((link) => link.remove());

  const head = document.head;
  const basePath = isVisible ? "/favicon" : "/favicon/blur.ico";

  // Add standard favicon (ICO)
  const icoLink = document.createElement("link");
  icoLink.rel = "shortcut icon";
  icoLink.type = "image/x-icon";
  icoLink.href = `${basePath}/favicon.ico`;
  head.appendChild(icoLink);

  // Add PNG favicon for better quality
  const pngLink = document.createElement("link");
  pngLink.rel = "icon";
  pngLink.type = "image/png";
  pngLink.setAttribute("sizes", "96x96");
  pngLink.href = `${basePath}/favicon-96x96.png`;
  head.appendChild(pngLink);

  // Add SVG favicon
  const svgLink = document.createElement("link");
  svgLink.rel = "icon";
  svgLink.type = "image/svg+xml";
  svgLink.href = `${basePath}/favicon.svg`;
  head.appendChild(svgLink);

  // Add Apple Touch Icon
  const appleLink = document.createElement("link");
  appleLink.rel = "apple-touch-icon";
  appleLink.href = `${basePath}/apple-touch-icon.png`;
  head.appendChild(appleLink);

  // Add Web App Manifest
  const manifestLink = document.createElement("link");
  manifestLink.rel = "manifest";
  manifestLink.href = `${basePath}/site.webmanifest`;
  head.appendChild(manifestLink);

  // Add Web App Icons
  const webAppIcon192 = document.createElement("link");
  webAppIcon192.rel = "icon";
  webAppIcon192.type = "image/png";
  webAppIcon192.setAttribute("sizes", "192x192");
  webAppIcon192.href = `${basePath}/web-app-manifest-192x192.png`;
  head.appendChild(webAppIcon192);

  const webAppIcon512 = document.createElement("link");
  webAppIcon512.rel = "icon";
  webAppIcon512.type = "image/png";
  webAppIcon512.setAttribute("sizes", "512x512");
  webAppIcon512.href = `${basePath}/web-app-manifest-512x512.png`;
  head.appendChild(webAppIcon512);
};

// Watch for visibility changes
watch(visibility, (isVisible) => {
  updateFavicon(isVisible === "visible");
});

onMounted(() => {
  updateCompletedGameFromCookies();
  // Set initial favicon based on current visibility
  updateFavicon(visibility.value === "visible");
});
</script>

<style></style>
